import { FC, useEffect, useRef } from "react";
import { PageLayout } from "../components/layout/PageLayout";
import { useLanguage } from "../features/localisation/useLanguage";
import { CircularProgress } from "@mui/material";
import { usePlayGames } from "../features/play_games/usePlayGames";
import Stack from "@mui/material/Stack";

import {SportType} from "../features/play_games/models/models";

export const SportPage: FC<{sportType: SportType}> = ({sportType}) => {
    const { languagePack: {pack: {argentina: {headerMenu: menuLang}}, langShort}} = useLanguage()
    const { openSportsBook, isSportsBookLoading, sportUrl } = usePlayGames()

    const prevSportType = useRef<SportType|undefined>()
    const prevLangShort = useRef<string|undefined>()

    useEffect(() => {
        if (prevSportType.current !== sportType || prevLangShort.current !== langShort) {
            prevSportType.current = sportType
            prevLangShort.current = langShort
            openSportsBook(sportType)
        }
    }, [langShort, openSportsBook, sportType]);

    return <PageLayout pageName={menuLang.sport} carouselDisabled flexGrowContentDisabled footerDisabled mobileBottomMenuDisabled>
        <Stack height={'100%'} width={'100%'} flexGrow={1}>
            {isSportsBookLoading ? <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'} flexGrow={1}>
                <CircularProgress/>
            </Stack> : sportUrl !== null ? <iframe title={'sport'} style={{flexGrow: 1, border: 'none'}} src={sportUrl}/> : <></>}
        </Stack>
    </PageLayout>
}