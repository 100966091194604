import {ApiError, ApiWarning} from "../../apiResponseModels";

interface ErrorState {
    apiError: ApiError | null
    warningsChannel: ApiWarning[]
}

export const initialState: ErrorState = {
    apiError: null,
    warningsChannel: []
}