import { FC, useRef } from "react";
import { usePlayGames } from "../../../features/play_games/usePlayGames";

const HTMLGameFrame: FC = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { gameSession } = usePlayGames();

    const srcDocContent = gameSession?.SessionHTML ? `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            html, body {
              height: 100%;
              margin: 0;
              padding: 0;
              overflow: hidden;
            }
          </style>
        </head>
        <body>
          ${gameSession.SessionHTML}
        </body>
      </html>
    ` : '';

    return (
        <iframe
            srcDoc={srcDocContent}
            style={{
                width: '100%',
                height: '100%',
                border: 'none',
                margin: '0',
                padding: '0',
                zIndex: 1000
            }}
            ref={iframeRef}
            title="Game Content"
        />
    );
}

export default HTMLGameFrame;
