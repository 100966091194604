import {GameHistory} from "./model";

export interface GameHistoryState {
    gameHistory: GameHistory[] | null
    pageSize: number
    currentPage: number
    totalRecords: number
    totalPages: number
    isGameHistoryLoading: boolean
}

export const initialState: GameHistoryState = {
    gameHistory: null,
    pageSize: 10,
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
    isGameHistoryLoading: false
}