import {ElementType, FC, useState} from "react";
import {alpha, Button, Stack, SvgIconProps} from "@mui/material";
import {Link} from "react-router-dom";
import HomeIcon from "../svg/HomeIcon";
import {SideMenuSection} from "./SideMenuSection";
import SevenIcon from "../../common/icons/Seven";
import SportIcon from "../svg/SportIcon";
import {useLanguage} from "../../../features/localisation/useLanguage";
import CasinoIcon from "../../common/icons/Casino";
import FastGamesIcon from "../../common/icons/FastGames";
import TableGamesIcon from "../../common/icons/TableGames";
import LiveCasinoIcon from "../../common/icons/LiveCasino";
import CupIcon from "../../common/icons/Cup";
import LiveInPlayIcon from "../../common/icons/LiveInPlay";
import LanguageSelector from "../language_selector/LanguageSelector";
import UserInfoIcon from "../svg/UserInfoIcon";
import TransactionIcon from "../svg/TransactionIcon";
import GameHistoryIcon from "../svg/GameHistoryIcon";
import LogoutIcon from "../svg/LogoutIcon";
import {LogOut} from "../LogOut";
import {useUser} from "../../../features/user/useUser";

export const SideMenu: FC = () => {
    const [expandedSectionId, setExpandedSectionId] = useState<string|undefined>()
    const { languagePack: {pack: {argentina: {headerMenu: menuLang, dashboard: {sideMenu: landDashboardMenu}} }}} = useLanguage()
    const [ showLogout, setShowLogout ] = useState<boolean>(false)
    const {token} = useUser()

    const handleAccordionChange = (sectionId: string) => () => {
        setExpandedSectionId(prevState => prevState === sectionId ? undefined : sectionId)
    };

    const MenuItem: FC<{text: string, icon: ElementType<SvgIconProps>, path?: string, onClick?: () => void}> = ({text, icon: Icon, path, onClick}) => {
        return <Button
            variant={'text'}
            {...(path !== undefined ? {component: Link, to: path} : {})}
            onClick={onClick}
            sx={theme => ({
                px: 1,
                justifyContent: 'start',
                color: alpha(theme.palette.text.primary, 0.6),
                gap: 1,
                textTransform: 'capitalize'
            })}
        >
            <Icon sx={theme => ({color: alpha(theme.palette.text.primary, 0.6)})}/>
            {text}
        </Button>
    }

    return <Stack
        justifyContent={'space-between'}
        height={'100%'}
        p={2}
    >
        <Stack
            gap={1}
        >
            <Button
                variant={'contained'}
                component={Link}
                to={'/'}
                sx={{
                    justifyContent: 'start',
                    color: 'black !important',
                    gap: 1,
                    textTransform: 'capitalize'
                }}
            >
                <HomeIcon sx={{color: 'black'}}/>
                {menuLang.home}
            </Button>

            <SideMenuSection
                expanded={expandedSectionId === 'games'}
                onExpandChanged={handleAccordionChange('games')}
                menuItems={[
                    {text: menuLang.casino, icon: LiveCasinoIcon, path: "/live_casino"},
                    {text: menuLang.crash, icon: FastGamesIcon, path: "/crash"},
                    {text: menuLang.tableGames, icon: TableGamesIcon, path: "/table_games"},
                    {text: menuLang.slots, icon: CasinoIcon, path: "/slots"},
                ]}
                sectionIcon={SevenIcon}
                sectionTitle={menuLang.gamesSubCatTitle}
            />
            <SideMenuSection
                expanded={expandedSectionId === 'sport'}
                onExpandChanged={handleAccordionChange('sport')}
                menuItems={[
                    {text: menuLang.sport, icon: LiveInPlayIcon, path: "/sport"},
                    {text: menuLang.sportPrelive, icon: CupIcon, path: "/sport_prelive"},
                ]}
                sectionIcon={SportIcon}
                sectionTitle={menuLang.sportSubCatTitle}
            />


            <MenuItem icon={UserInfoIcon} text={landDashboardMenu.userInfo} path={'/dashboard/user_info'}/>
            <MenuItem icon={TransactionIcon} text={landDashboardMenu.transactions} path={'/dashboard/transactions'}/>
            <MenuItem icon={GameHistoryIcon} text={landDashboardMenu.gamesHistory} path={'/dashboard/game_history'}/>
            {token && <MenuItem
                icon={LogoutIcon} text={landDashboardMenu.logoutBTN}
                onClick={() => setShowLogout(true)}
            />}
        </Stack>

            <LanguageSelector fullWidth shortMode={false}/>

        {showLogout && <LogOut close={() => {
            setShowLogout(false)
        }}/>}
    </Stack>
}