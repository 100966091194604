import { LangPack } from "./langModel";
import { Lang } from "./useLanguageInterface";
import { ruLang } from "./packs/ruLang";
import { enLang } from "./packs/enLang";
import { esLang } from "./packs/esLang";
import { ptLang } from "./packs/ptLang";

export const getLangPackByName = (lang: Lang): LangPack => {
    switch (lang) {
        case Lang.RU:
            return ruLang
        case Lang.ES:
            return esLang
        case Lang.PT:
            return ptLang
        case Lang.EN:
        default:
            return enLang
    }
}