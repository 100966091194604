import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LoaderAction} from "../common_models";
import {groupGamesByProviders} from "./funcs";
import {GameObj, GameProvider, GameSession} from "./models/models";
import {initialState} from "./models/state";
import {PlayGamesLoader} from "./models/loaders";

const PlayGamesSlice = createSlice({
    name: 'playGames',
    initialState,
    reducers: {
        resetPlayGamesSlice: (state) => {
            state.providers = null
            state.providersFiltered = null
            state.selectedProvider = null
            state.games = null
            state.groupedGames = null
            state.gameSession = {
                SessionId: null,
                SessionUrl: null,
                SessionHTML: null
            }
            state.gameType = null
            state.sportUrl = null

            state.isProvidersOutcomeLoading = false
            state.isGamesLoading = false
            state.isGameSessionLoading = false
            state.isSportsBookLoading = false
        },
        setPlayGamesLoader: (state, action: PayloadAction<LoaderAction<PlayGamesLoader>>) => {
            switch (action.payload.loader) {
                case PlayGamesLoader.isProvidersOutcomeLoading:
                    state.isProvidersOutcomeLoading = action.payload.isLoading
                    break
                case PlayGamesLoader.isOutcomeGameLoading:
                    state.isGamesLoading = action.payload.isLoading
                    break
                case PlayGamesLoader.isGameSessionLoading:
                    state.isGameSessionLoading = action.payload.isLoading
                    break
                case PlayGamesLoader.isSportsBookLoading:
                    state.isSportsBookLoading = action.payload.isLoading
                    break
            }
        },
        setProvidersOutcome: (state, action: PayloadAction<GameProvider[]>) => {
            state.providers = action.payload
        },
        setSelectedProviderName: (state, action: PayloadAction<string|null>) => {
            state.selectedProvider = action.payload
        },
        setOutcomeGames: (state, action: PayloadAction<GameObj[]|null>) => {
            state.games = action.payload
            if (state.providers && action.payload) {
                state.groupedGames = groupGamesByProviders(state.providers, action.payload, state.gameType)
            }
        },
        setGameSession: (state, action: PayloadAction<GameSession>) => {
            state.gameSession = action.payload
        },
        reFilterGamesByType: (state, action: PayloadAction<string|null>) => {
            state.gameType = action.payload
            if (state.providers && state.games) {
                state.groupedGames = groupGamesByProviders(state.providers, state.games, state.gameType)
            }
        },
        setSportUrl: (state, action: PayloadAction<string|null>) => {
            state.sportUrl = action.payload
        }
    }
})

export default PlayGamesSlice.reducer;

export const {
    resetPlayGamesSlice,
    setPlayGamesLoader,
    setProvidersOutcome,
    setSelectedProviderName,
    setOutcomeGames,
    setGameSession,
    reFilterGamesByType,
    setSportUrl
} = PlayGamesSlice.actions