import { FC, useEffect, useRef, useState } from "react";
import { PageLayout } from "../components/layout/PageLayout";
import { Box } from "@mui/material";
import { ProvidersListSection } from "../components/layout/providers_section/ProvidersListSection";
import { Games } from "../components/layout/games/Games";
import { useAppDispatch } from "../store";
import { reFilterGamesByType } from "../features/play_games/playGamesSlice";

interface GamesPageProps {
    gameType?: string
    title: string
}

export const GamesPage: FC<GamesPageProps> = ({gameType = null, title}) => {
    const dispatch = useAppDispatch()
    const [providerName, setProviderName] = useState<string | null>(null)
    const gameTypePrev = useRef<string | null>(null)
    
    useEffect(() => {
            if (gameTypePrev.current !== gameType) {
                dispatch(reFilterGamesByType(gameType))
                gameTypePrev.current = gameType
            }
    }, [dispatch, gameType])

    return <PageLayout pageName={providerName ?? title}>
        <Box px={'8px'} mb={'24px'}>
            <ProvidersListSection/>
        </Box>
        <Box px={'8px'} mb={'24px'}>
            <Games onProviderNameChanged={setProviderName}/>
        </Box>
    </PageLayout>
}