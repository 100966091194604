import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Header } from "./header/Header";
import { OverlaySpinner } from "../common/loaders/OverlaySpinner";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import {Footer} from "./footer/Footer";
import {BottomMobileMenu} from "./bottom_menu/BottomMobileMenu";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {SideMenu} from "./side_menu/SideMenu";
import {CarouselSlider} from "../carousel/CarouselSlider";
import Slide1M from '../../../assets/oropuro_redesigned_v2/carousel/live_casino_banner_mobile_es.webp'
import Slide1D from '../../../assets/oropuro_redesigned_v2/carousel/live_casino_banner_es.webp'
import Slide2M from '../../../assets/oropuro_redesigned_v2/carousel/slots_banner_mobile_es.webp'
import Slide2D from '../../../assets/oropuro_redesigned_v2/carousel/slots_banner_es.webp'
import Slide3M from '../../../assets/oropuro_redesigned_v2/carousel/sports_banner_mobile_es.webp'
import Slide3D from '../../../assets/oropuro_redesigned_v2/carousel/sports_banner_es.webp'
import {SideMenuDrawer} from "./side_menu/SideMenuDrawer";
import {FooterDisclaimer} from "./footer/FooterDisclaimer";

interface PageLayoutProps {
    children: ReactNode
    pageName?: string;
    carouselDisabled?: boolean
    footerDisabled?: boolean
    flexGrowContentDisabled?: boolean
    mobileBottomMenuDisabled?: boolean
}

export const PageLayout: FC<PageLayoutProps> = ({ children, pageName, carouselDisabled = false, footerDisabled = false, flexGrowContentDisabled = false, mobileBottomMenuDisabled = false }) => {
    const { isGameSessionLoading, isGamesLoading } = usePlayGames()
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [scrollContainerHeight, setScrollContainerHeight] = useState(0);
    const { largeScreen: lg } = useCommonMediaQueries()
    const [drawerOpened, setDrawerOpened] = useState<boolean>(false);

    useEffect(() => {
        const siteName = process.env.REACT_APP_SITE_NAME ?? "Casino"
        document.title = pageName ? `${siteName} | ${pageName}` : siteName;

        return () => {
            document.title = siteName;
        };
    }, [pageName]);

    useEffect(() => {
            if (scrollContainerRef.current) {
                const height = scrollContainerRef.current.offsetHeight;
                setScrollContainerHeight(height);
            }
    }, []);

    return <Stack
        height={'100dvh'}
    >
        <Header/>
        <Box
            display={'flex'}
            flexGrow={1}
            overflow={'hidden'}
        >
            {!lg ? <Box
                position={'sticky'}
                top={0}
                left={0}
                bottom={0}
                minWidth={'260px'}
                width={'260px'}
                maxWidth={'260px'}
            >
                <SideMenu/>
            </Box> :
            <SideMenuDrawer opened={drawerOpened} onClose={setDrawerOpened}/>}

            <Stack
                width={'100%'}
                maxHeight={'100%'}
                sx={{
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#15181D #15181D60'
                }}
                ref={scrollContainerRef}
            >
                <Stack
                    position={'relative'}
                    minHeight={scrollContainerHeight}
                    gap={2}
                >
                    {!carouselDisabled && <CarouselSlider
                        sliders={[
                            {
                                link: '/slots',
                                desktopPicUrl: Slide1D,
                                mobilePicUrl: Slide1M
                            },
                            {
                                link: '/live_casino',
                                desktopPicUrl: Slide2D,
                                mobilePicUrl: Slide2M
                            },
                            {
                                link: '/sport',
                                desktopPicUrl: Slide3D,
                                mobilePicUrl: Slide3M
                            }
                        ]}
                    />}
                    {children}
                    {!flexGrowContentDisabled && <Box flexGrow={1}/>}
                    {!footerDisabled && <>
                        <FooterDisclaimer/>
                        <Footer scrollContainerRef={scrollContainerRef}/>
                    </>}
                    <Box minHeight={theme => theme.spacing(1)}/>
                </Stack>
                {(isGamesLoading || isGameSessionLoading) && <OverlaySpinner/>}
            </Stack>

        </Box>

        {lg && !mobileBottomMenuDisabled && <BottomMobileMenu requestSwitchDrawer={() => setDrawerOpened(prevState => !prevState)}/>}
    </Stack>
}