import {MoneyTransaction} from "./model";

interface TransactionsState {
    transactions: MoneyTransaction[]
    pageSize: number
    currentPage: number
    totalRecords: number
    totalPages: number
    isTransactionsLoading: boolean
}

export const initialState: TransactionsState = {
    transactions: [],
    pageSize: 10,
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
    isTransactionsLoading: false
}