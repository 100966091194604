import {GameObj, GameProvider, GameSession, ProviderGroup} from "./models";

export interface PlayGamesState {
    gameType: string | null
    providers: GameProvider[] | null
    providersFiltered: GameProvider[] | null // only providers which have games
    selectedProvider: string | null
    games: GameObj[] | null
    groupedGames: ProviderGroup[] | null // games grouped by provider
    gameSession: GameSession
    sportUrl: string | null

    isProvidersOutcomeLoading: boolean
    isGamesLoading: boolean
    isGameSessionLoading: boolean
    isSportsBookLoading: boolean
}

export const initialState: PlayGamesState = {
    gameType: null,
    providers: null,
    providersFiltered: null,
    selectedProvider: null,
    games: null,
    groupedGames: null,
    gameSession: {
        SessionId: null,
        SessionUrl: null,
        SessionHTML: null
    },
    sportUrl: null,

    isProvidersOutcomeLoading: false,
    isGamesLoading: false,
    isGameSessionLoading: false,
    isSportsBookLoading: false
}