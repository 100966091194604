import {PaymentAccount, PaymentMethodTemplate} from "./model";

export interface PaymentSystemsState {
    userPaymentAccounts: PaymentAccount[] | null
    isPaymentAccountsLoading: boolean
    isPaymentAccountAddLoading: boolean

    paymentMethods: PaymentMethodTemplate[] | null // Available payment methods for this platform filtered
    unfilteredPaymentMethods: PaymentMethodTemplate[] | null// Available payment methods for this platform unfiltered
    isPaymentMethodsLoading: boolean

    userPayoutAccounts: PaymentAccount[] | null // Payment accounts of cashier of current user from which user receiving money to his payment account
    isPayoutAccountsLoading: boolean
    isRequestPayoutLoading: boolean // When user request payout to him payment account (from user balance to credit card for example)

    isBonusRequestLoading: boolean // When user enter promo-code and front sending request to the api for checking all conditions
    isPaymentRequestLoading: boolean // When user request payment
}

export const initialState: PaymentSystemsState = {
    userPaymentAccounts: null,
    isPaymentAccountsLoading: false,
    isPaymentAccountAddLoading: false,

    paymentMethods: null,
    unfilteredPaymentMethods: null,
    isPaymentMethodsLoading: false,

    userPayoutAccounts: null,
    isPayoutAccountsLoading: false,
    isRequestPayoutLoading: false,

    isBonusRequestLoading: false,
    isPaymentRequestLoading: false
}