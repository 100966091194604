import { FC, useEffect, useState } from "react";
import { PageLayout } from "../components/layout/PageLayout";
import { TopGamesSection } from "../components/layout/top-games-section/TopGamesSection";
import {Box} from "@mui/material";
import { ProvidersListSection } from "../components/layout/providers_section/ProvidersListSection";
import { useLanguage } from "../features/localisation/useLanguage";
import { reFilterGamesByType } from "../features/play_games/playGamesSlice";
import { useAppDispatch } from "../store";
import {CategoriesSection} from "../components/layout/category_cards/CategoriesSection";
import {GamesSection} from "../components/games/GamesSection";
import CasinoIcon from "../components/common/icons/Casino";
import {SectionDivider} from "../components/layout/section_devider/SectionDevider";
import ProvidersIcon from "../components/common/icons/Providers";
import LiveCasinoIcon from "../components/common/icons/LiveCasino";
import TableGamesIcon from "../components/common/icons/TableGames";
import {LatestWinningsTable} from "../components/latest_winnings/LatestWinningsTable";
import {useUser} from "../features/user/useUser";
import {useCommonMediaQueries} from "../features/common_funcs/mediaQueries/useCommonMediaQueries";


export const HomePage: FC = () => {
    const { user } = useUser()
    const dispatch = useAppDispatch()
    const { languagePack: { pack: { argentina: { common: lang, headerMenu: langHeader } } } } = useLanguage()
    const [justMounted, setJustMounted] = useState<boolean>(true)
    const {smallScreen: sm} = useCommonMediaQueries()

    useEffect(() => {
        if (justMounted) {
            setJustMounted(false)
                dispatch(reFilterGamesByType(null))
        }
    }, [dispatch, justMounted])

    return <PageLayout pageName={'Home'}>
        <Box px={sm ? 0 : '8px'} mt={2}>
            <CategoriesSection/>
        </Box>
        <Box px={'8px'} mb={'24px'}>
            <GamesSection
                icon={CasinoIcon}
                title={langHeader.gamesSubCatTitle}
                link={'/games'}
            />
        </Box>
        <Box padding={'24px 8px'}>
            <TopGamesSection/>
        </Box>
        <Box px={'8px'}>
            <SectionDivider
                icon={ProvidersIcon}
                title={lang.providers}
                showLeftRightButtons={false}
            />
        </Box>
        <Box px={'8px'} mb={'24px'}>
            <ProvidersListSection/>
        </Box>
        <Box px={'8px'} mb={'24px'}>
            <GamesSection
                icon={LiveCasinoIcon}
                title={langHeader.liveCasino}
                link={'/live_casino'}
                gameType={'live_casino'}
            />
        </Box>
        <Box px={'8px'} mb={'24px'}>
            <GamesSection
                icon={TableGamesIcon}
                title={langHeader.tableGames}
                link={'/table_games'}
                gameType={'table_games'}
            />
        </Box>
        {user && <Box px={'8px'} mb={'24px'}>
            <LatestWinningsTable/>
        </Box>}

    </PageLayout>
}