import { FC } from "react";
import { Stack } from "@mui/material";
import { getMediaCDN} from "../../../config";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {GameObj} from "../../../features/play_games/models/models";
import {GameCard} from "./GameCard";

interface ShortGamesListProps {
    games: GameObj[]
    onGameClick?: (game: GameObj) => void
}

export const ShortGamesList: FC<ShortGamesListProps> = ({games, onGameClick}) => {
    const cdn = getMediaCDN()
    const { smallScreen } = useCommonMediaQueries()
    const width = smallScreen ? '100px' : '9.8vw'

    return <Stack
        py={'5px'}
        direction={'row'}
        sx={{overflowX: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#15181D #15181D60', userSelect: 'none'}}
        gap={smallScreen ? '10px' : '1vw'}
        justifyContent={smallScreen || games.length < 9 ? 'start' : 'space-between'}
    >
        {games.map(game => <GameCard key={game.internal_id} cdn={cdn ?? '/cdn'} game={game} onGameClick={onGameClick}
                                     width={width}/>)}
    </Stack>
}