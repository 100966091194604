import {initial} from "./models/state";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TopWinGame} from "./models/model";
import {LoaderAction} from "../common_models";
import {TopWinsLoader} from "./models/loaders";

const TopWinsSlice = createSlice({
    name: 'topWins',
    initialState: initial,
    reducers: {
        resetTopWins: (state) => {
            state.topWins = null
            state.isTopWinsLoading = false
        },
        setTopWinsLoader(state, action: PayloadAction<LoaderAction<TopWinsLoader>>) {
            switch (action.payload.loader) {
                case TopWinsLoader.isTopWinsLoading:
                    state.isTopWinsLoading = action.payload.isLoading
                    break
            }
        },
        setTopWins(state, action: PayloadAction<TopWinGame[] | null>) {
            state.topWins = action.payload
        }
    }
});

export default TopWinsSlice.reducer;

export const {
    setTopWins,
    setTopWinsLoader,
    resetTopWins
} = TopWinsSlice.actions