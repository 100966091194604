import {User} from "./model";
import UserStorage from "../../../localStorage/userStorage";
import CashbackStorage from "../../../localStorage/cashbackStorage";

export interface UserState {
    token: string | null
    user: User | null
    cashback: number | null
    isLogInLoading: boolean
    isUserInfoUpdateLoading: boolean
    isChangePasswordLoading: boolean
}

export const initialState: UserState = {
    token: UserStorage.getToken(),
    user: UserStorage.getUser(),
    cashback: CashbackStorage.getCashback(),
    isLogInLoading: false,
    isUserInfoUpdateLoading: false,
    isChangePasswordLoading: false
}