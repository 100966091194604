import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {PaymentAccount, PaymentMethodTemplate} from "./models/model";
import {initialState} from "./models/state";

const PaymentSystemsSlice = createSlice({
    name: 'paymentSystems',
    initialState,
    reducers: {
        // Resetting current slice (e.g., when the component is closed).
        clearPaymentSystems: (state) => {
            state.userPaymentAccounts = null
            state.paymentMethods = null
            state.unfilteredPaymentMethods = null
            state.userPayoutAccounts = null

            state.isPaymentAccountsLoading = false
            state.isPaymentAccountAddLoading = false
            state.isPaymentMethodsLoading = false
            state.isPayoutAccountsLoading = false
            state.isRequestPayoutLoading = false
        },
        // Set user payment accounts list
        setUserPaymentAccounts: (state, action: PayloadAction<PaymentAccount[]>) => {
            state.userPaymentAccounts = action.payload
            if (state.paymentMethods !== null) {
                // Filter methods which user not added yet
                const userPaymentAccIds = state.userPaymentAccounts.map(paymentAcc => paymentAcc.method_id)
                state.paymentMethods = state.paymentMethods.filter(method => !userPaymentAccIds.includes(method.id))
            }
        },
        // Add new payment account to the end of user payments list
        addUserPaymentAccountToList: (state, action: PayloadAction<PaymentAccount>) => {
            if (state.userPaymentAccounts) {
                state.userPaymentAccounts = [
                    ...state.userPaymentAccounts,
                    action.payload
                ]
            } else {
                state.userPaymentAccounts = [
                    action.payload
                ]
            }
            if (state.paymentMethods !== null) {
                // Filter methods which user not added yet
                const userPaymentAccIds = state.userPaymentAccounts.map(paymentAcc => paymentAcc.method_id)
                state.paymentMethods = state.paymentMethods.filter(method => !userPaymentAccIds.includes(method.id))
            }
        },
        // Change loading state isPaymentAccountAddLoading when adding new user payment account
        setPaymentAccountAddLoading: (state, action: PayloadAction<boolean>) => {
            state.isPaymentAccountAddLoading = action.payload
        },

        // Change loading state isPaymentAccountsLoading for userPaymentAccounts
        setPaymentAccountsLoading: (state, action: PayloadAction<boolean>) => {
            state.isPaymentAccountsLoading = action.payload
        },
        // Set payment methods list
        setUserPaymentMethods: (state, action: PayloadAction<PaymentMethodTemplate[]>) => {
            state.unfilteredPaymentMethods = action.payload
            if (state.userPaymentAccounts) {
                // Filter methods which user not added yet
                const userPaymentAccIds = state.userPaymentAccounts.map(paymentAcc => paymentAcc.method_id)
                state.paymentMethods = action.payload.filter(method => !userPaymentAccIds.includes(method.id))
            } else {
                state.paymentMethods = action.payload
            }
        },
        // Change loading state isPaymentMethodsLoading for paymentMethods
        setPaymentMethodsLoading: (state, action: PayloadAction<boolean>) => {
            state.isPaymentMethodsLoading = action.payload
        },

        // Set cashier payment accounts list
        setUserPayoutAccounts: (state, action: PayloadAction<PaymentAccount[]>) => {
            state.userPayoutAccounts = action.payload
        },
        // Change loading state isPayoutAccountsLoading for userPayoutAccounts
        setPayoutAccountsLoading: (state, action: PayloadAction<boolean>) => {
            state.isPayoutAccountsLoading = action.payload
        },
        // Change loading state isRequestPayoutLoading when user request payout
        setRequestPayoutLoading: (state, action: PayloadAction<boolean>) => {
            state.isRequestPayoutLoading = action.payload
        },
        // Change loading state isBonusRequestLoading when user request RequestBonus
        setBonusRequestLoading: (state, action: PayloadAction<boolean>) => {
            state.isBonusRequestLoading = action.payload
        },
        // Change loading state isPaymentRequestLoading when user request RequestPayment
        setPaymentRequestLoading: (state, action: PayloadAction<boolean>) => {
            state.isPaymentRequestLoading = action.payload
        },
    }
})

export default PaymentSystemsSlice.reducer

export const {
    clearPaymentSystems,
    setUserPaymentAccounts,
    setPaymentAccountsLoading,
    addUserPaymentAccountToList,
    setPaymentAccountAddLoading,

    setUserPaymentMethods,
    setPaymentMethodsLoading,

    setUserPayoutAccounts,
    setPayoutAccountsLoading,
    setRequestPayoutLoading,

    setBonusRequestLoading,
    setPaymentRequestLoading
} = PaymentSystemsSlice.actions