import React, { FC, useEffect } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { PageSizeSwitcher } from "../../../components/layout/page/page_size_switcher/PageSizeSwitcher";
import { Paginator } from "../../../components/common/paginator/Paginator";
import { useGamesHistoryWatcher } from "../../../features/gameHistory/useGamesHistoryWatcher";
import { GamesHistoryItemCard } from "../../../components/games_history/GamesHistoryItemCard";
import { useGamesHistory } from "../../../features/gameHistory/useGamesHistory";
import { useUser } from "../../../features/user/useUser";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { GameHistoryTable } from "../../../components/games_history/GameHistoryTable";
import { useLanguage } from "../../../features/localisation/useLanguage";
import {SectionDividerSimple} from "../../../components/layout/section_devider/SectionDividerSimple";
import {DashboardMobileTitle} from "../../../components/layout/dashboard_mobile_title/DashboardMobileTitle";
import {PageLayout} from "../../../components/layout/PageLayout";

export const GamesHistory: FC = () => {
    const { languagePack: { pack: { argentina: { dashboard: { sideMenu: menuLang} } } } } = useLanguage()
    useGamesHistoryWatcher()
    const { pageSize, currentPage, totalPages, clearGameHistory, gameHistory } = useGamesHistory()
    const {user, token} = useUser()
    const { mediumScreenMin: mdMin, mediumScreen: md } = useCommonMediaQueries()

    useEffect(() => {
        return () => {
            clearGameHistory()
        }
    }, [ clearGameHistory ])

    return <PageLayout pageName={menuLang.gamesHistory} carouselDisabled>
        {mdMin ? <SectionDividerSimple mt={2}>{menuLang.gamesHistory}</SectionDividerSimple> : <DashboardMobileTitle title={menuLang.gamesHistory}/>}

        {user && token && <Stack padding={'16px'} gap={'12px'}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography fontSize={'28px'}>
                    {menuLang.gamesHistory}
                </Typography>
                <PageSizeSwitcher pageSize={pageSize}/>
            </Stack>
            {md ? (gameHistory && gameHistory.map(game => <GamesHistoryItemCard currency={user?.user_currency}
                                                                                          key={game.uuid}
                                                                                          gameHistoryItem={game}/>)) :
                <GameHistoryTable/>}
            <Stack direction={'row'} justifyContent={'end'}>
                <Paginator totalPages={totalPages} currentPage={currentPage}/>
            </Stack>
        </Stack>}
    </PageLayout>
}