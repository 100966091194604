import React, { FC, useEffect, useRef, useState } from "react";
import {alpha, Box, Button, Stack, SxProps, Theme} from "@mui/material";
import { Logo } from "../logo/Logo";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { LoginWindow } from "../login/LoginWindow";
import { LoginForm } from "../../common/login/LoginForm";
import { useUser } from "../../../features/user/useUser";
import { Link, useLocation } from "react-router-dom";
import { UserTitle } from "./UserTitle";
import { ShowBalance } from "../balance/ShowBalance";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { HeaderDecorationBottom } from "./HeaderDecorationBottom";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { GameSearchWindow } from "../GameSearchWindow";
import CasinoIcon from "../../common/icons/Casino";
import LiveSupport2Icon from "../../common/icons/LiveSupport2";
import SearchIcon from "../../common/icons/Search";
import SportIcon from "../svg/SportIcon";

interface HeaderProps {
    onHeightChange?: (height: number) => void;
}

export const Header: FC<HeaderProps> = ({ onHeightChange }) => {
    const location = useLocation()
    const { languagePack: {pack: {argentina: {headerMenu: menuLang}, loginForm: loginLang}}} = useLanguage()
    const headerRef = useRef<HTMLDivElement>(null);
    const { xxSmallScreen, smallScreen: sm } = useCommonMediaQueries()
    const [loginWindowOpen, setLoginWindowOpen] = useState(false);
    const { token, user, refreshUserBalance } = useUser()
    const [justMounted, setJustMounted] = useState(true)
    const [ searchWindowOpen, setSearchWindowOpen ] = useState(false)
    
    useEffect(() => {
        if (justMounted && user && token) {
            refreshUserBalance()
            setJustMounted(false)
        }
    }, [justMounted, refreshUserBalance, token, user])

    useEffect(() => {
        if (onHeightChange) {
            if (headerRef.current) {
                const height = headerRef.current.offsetHeight;
                onHeightChange(height);
            }
        }
    }, [onHeightChange]);

    const btnSX: SxProps<Theme> = {
        background: '#292C31',
        color: 'inherit',
        boxShadow: 'none',
        '&:hover': {
            background: alpha('#fff', 0.1),
            boxShadow: 'none'
        }
    }

    return (
        <>
            <Stack ref={headerRef} sx={theme => ({backgroundColor: theme.palette.surface.main})}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    px={'10px'}
                    py={'5px'}
                >
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        gap={4}
                    >
                        <Logo/>

                        {!sm && <Box
                            p={0.3}
                            borderRadius={'16px'}
                            display={'flex'}
                            alignItems={'center'}
                            sx={{color: '#18171F', backgroundColor: '#292C31'}}
                            gap={1}
                        >
                            <Button
                                component={Link}
                                to={'/casino'}
                                variant="contained"
                                startIcon={<CasinoIcon/>}
                            >
                                {menuLang.casino}
                            </Button>
                            <Button
                                component={Link}
                                to={'/sport'}
                                variant="contained"
                                startIcon={<SportIcon sx={{color: alpha('#E4DCD5', 0.6)}}/>}
                                sx={{
                                    transition: 'background-color .3s ease',
                                    backgroundColor: 'transparent',
                                    background: 'none',
                                    boxShadow: 'none',
                                    color: '#E4DCD5 !important',
                                    '&:hover': {
                                        backgroundColor: alpha('#E4DCD5', 0.1),
                                    }
                                }}
                            >
                                {menuLang.sport}
                            </Button>
                        </Box>}
                    </Box>

                    <Stack direction={'row'} alignItems={'center'} gap={'6px'} sx={{
                        color: '#FDD887'
                    }}>

                            <Button
                                onClick={() => {
                                    // @ts-ignore
                                    if (window.Tawk_API) {
                                        // @ts-ignore
                                        window.Tawk_API.maximize()
                                    }
                                }}
                                variant={'contained'}
                                sx={btnSX}
                            >
                                <LiveSupport2Icon fontSize={'large'}/>
                            </Button>
                            {!location.pathname.match(/\/(dashboard)/) && <Button
                                onClick={() => {
                                    console.log('Search window open')
                                    setSearchWindowOpen(true)
                                }}
                                sx={btnSX}
                            >
                                <SearchIcon fontSize={'large'}/>
                            </Button>}

                        {(token && user) ? !sm &&
                            <Stack direction={'row'}>
                                <Stack
                                    justifyContent={'end'}
                                    px={'10px'}
                                    py={'2px'}
                                >
                                    <Typography
                                        px={'8px'}
                                        textTransform={'uppercase'}
                                        fontWeight={600}
                                        fontSize={'14px'}
                                        textAlign={'right'}
                                        sx={{ userSelect: 'none' }}
                                    >
                                        {user.user_name}
                                    </Typography>
                                    <Stack
                                        minWidth={'150px'}
                                        direction={'row'}
                                        justifyContent={'space-between'}
                                        alignItems={'center'}
                                        gap={'5px'}
                                    >
                                        <ShowBalance balance={user.user_balance} currency={user.user_currency ?? '--'}/>
                                        {/*<ShowBalance balance={user.user_bonus_balance} currency={'Bonus'}/>*/}
                                    </Stack>
                                </Stack>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Link to={'/dashboard'}><IconButton>
                                        <AccountCircleIcon color={'primary'} sx={{ fontSize: '50px' }}/>
                                    </IconButton></Link>
                                </Box>
                            </Stack> : (sm ?
                            <Button onClick={() => setLoginWindowOpen(true)} variant={'outlined'}>{loginLang.loginBTN}</Button> :
                            <Stack direction={'row'} gap={'10px'}>
                                <LoginForm/>
                            </Stack>)
                        }
                        {/*{!sm && <LanguageSelector/>}*/}
                    </Stack>
                </Stack>

                {token && user  && sm  && <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    px={'10px'}
                    py={'5px'}
                    gap={'5px'}
                >
                    <Box flex={1}>
                        <ShowBalance balance={user.user_balance} currency={user.user_currency ?? '--'}/>
                    </Box>
                    <Box flex={1}>
                    <Link to={'/dashboard'}><UserTitle username={user.user_name}/></Link>
                    </Box>
                    {/*<ShowBalance balance={user.user_bonus_balance} currency={'Bonus'}/>*/}
                </Stack>}

                {!sm && <HeaderDecorationBottom/>}
            </Stack>

            { searchWindowOpen && <GameSearchWindow onClose={() => setSearchWindowOpen(false)}/>}
            { (xxSmallScreen || sm) && loginWindowOpen && <LoginWindow close={() => setLoginWindowOpen(false)}/> }
        </>

    );
};
